import TIM from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';

import genTestUserSig from './GenerateTestUserSig';

let Status = 'NOT_READY';

let options = {
  SDKAppID: 1400739111 // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
};

// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
console.log('%c FUBOO CREATE TIM','color:blue');
const tim = TIM.create(options); // SDK 实例通常用 tim 表示

tim.getStatus = ()=>{
    return Status;
}
// 设置 SDK 日志输出级别，详细分级请参见 setLogLevel https://web.sdk.qcloud.com/im/doc/zh-cn/SDK.html#setLogLevel 接口的说明</a>
//tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用

// 注册腾讯云即时通信 IM 上传插件
tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});

const onSdkReady=(event)=>{
  console.log('%c tim onSdkReady:%o','color:blue',event);
  Status='READY';
}
tim.on(TIM.EVENT.SDK_READY, onSdkReady);

// SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态
let onSdkNotReady = function(event) {
  // 如果想使用发送消息等功能，接入侧需驱动 SDK 进入 ready 状态，重新调用 login 接口即可，如下所示：
  // tim.login({userID: 'your userID', userSig: 'your userSig'});
  console.log('%c tim onSdkNotReady:%o','color:blue',event);
  Status='NOT_READY';

};
tim.on(TIM.EVENT.SDK_NOT_READY, onSdkNotReady);

// tim.joinGroup({
//   groupID:'tim_01',
//   applyMessage:'join tim_01'
// }).then(join_ret=>{
//   console.log('%c tim.join_ret:%o','color:blue',join_ret);
// });

export default tim;
