export const Box = (props)=>{
    return (
        <div className="fx-box fx-fd-c live-box">
            <div >
                <img src={props.poster}></img>
            </div>
            
            <div>
                {props.title}
            </div>
        </div>
    )
}