import { useLocation, useNavigate } from "react-router-dom"

export const Menu = (props)=>{
    const navigate= useNavigate();
    const location = useLocation();

    return (
        <ul className="nav-menu">
            <li className={"btn"+(location.pathname==='/'?' active':'')} onClick={()=>{
                navigate('/')
            }}>
                首页
            </li>
            <li className={"btn"+(location.pathname.startsWith('/show')?' active':'')} onClick={()=>{
                navigate('/show/6040289')
            }}>
                表演
            </li>
            <li className={"btn"+(location.pathname==='/charge'?' active':'')} onClick={()=>{
                navigate('/charge')
            }}>
                充值
            </li>
        </ul>
    )
}
