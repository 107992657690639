import { HashRouter as Router,Routes, Route,Outlet ,Navigate, useNavigate } from 'react-router-dom'

import {Layout} from './pages/Layout';
import {Home}  from './pages/Home';
import {Profile} from './pages/Profile';
import { Charge } from './pages/Charge';
import { Pay } from './pages/Charge/pay';
import { Show } from './pages/Show';
import { connect } from 'react-redux';
import tim from './tim';
import { useEffect } from 'react';
import {ChargeOK} from './pages/Charge/chargeok';


import * as service from './service';


export default connect(
    state=>{
        return(
            {loginInfo:state.loginInfo,
                tim:state.tim
            }
        )
    },
    dispatch=>({dispatch})
)((props)=>{
    useEffect(()=>{
        if(props.loginInfo&&!props.tim){
            console.log(props.loginInfo);
            service.usersig().then((ret)=>{
                return ret.data;
            }).then((options)=>{
                return tim.login({userID:''+options.userId,userSig:options.userSig})
            }).then(()=>{
                props.dispatch({type:'TIM_LOGINED'});
            }).catch(err=>{
                console.error('FUBOO TIM LOGIN ERROR');
                props.dispatch({type:'TIM_LOGIN_FAIL',payload:err});
            })
        }
    },[props.loginInfo,props.tim])
    
    return (
        <Router>                
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route path="" element={<Home/>}/>
                    <Route path='profile' element={<Profile/>}/>
                    <Route path = 'charge' element={<Charge/>}/>
                    <Route path = 'chargeok/:id' element={<ChargeOK/>}/>
                    <Route path = 'pay' element={<Pay/>}/>
                    <Route path = 'show/:id' element = {<Show/>}/>
                </Route>
            </Routes>
        </Router>
    )
})