import { createStore } from 'redux'

const DEFAULT_MESSAGES=[
    {sender:'系统消息',content:'欢迎您进入秀场'},
    {sender:'系统消息',content:'认准官方账号特征，谨防您的权益受损！'},
    {sender:'系统消息',content:'如发现色情、暴力、欺诈、谩骂等请使用举报功能！'},
    {sender:'直播间表演时间',content:'上午8:30-11:30 下午13:30-17:30'},
]
const InitialState = {
    loginInfo:null,
    showLoginDialog:false,
    messages:DEFAULT_MESSAGES,
    currentGroup:null
}

const rootReducer=(state=InitialState,action)=>{
    console.log('%c REDUX ACTION:%o','color:blue',action);
    switch(action.type){
        case 'LOGIN_OK':
            return Object.assign({},state,{loginInfo:action.payload,tim:null})
        case 'LOGOUT':
            return InitialState;
        case 'SHOW_LOGIN_DIALOG':
            return Object.assign({},state,{showLoginDialog:true});
        case 'CLOSE_LOGIN_DIALOG':
            return Object.assign({},state,{showLoginDialog:false});
        case 'TIM_LOGINED':
            return Object.assign({},state,{tim:{}})
        case 'TIM_LOGIN_FAIL':
            return Object.assign({},state,{tim:{error:action.payload}})
        case 'MESSAGE_RECEIVE':
            const messages1=state.messages.slice();
            messages1.push(action.payload);
            return Object.assign({},state,{messages:messages1})
        case 'MESSAGE_SEND':
            const messages2=state.messages.slice();
            messages2.push(action.payload);
            return Object.assign({},state,{messages:messages2})
        case 'JOIN_GROUP':
            return Object.assign({},state,{currentGroup:action.payload})
        case 'QUIT_GROUP':
                return Object.assign({},state,{currentGroup:null})
        default:
            return state;
    }
}

export const store= createStore(rootReducer);