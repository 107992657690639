import { Box } from './Box';
import './home.css';

import { STATICROOMS,offRooms, DefaultPosterUrl } from '../../mock';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

import * as service from '../../service';



export const Home= (props)=>{
    const [rooms,setRooms] = useState(null);
    useEffect(()=>{
        (!rooms)&&service.getRooms({}).then(data=>{
            console.log('%c rooms:%o','color:blue',data);
            const t = STATICROOMS.concat(data?.data?.data);
            setRooms(t);
        })
    },[setRooms,rooms])

    return (
        <div className='fx-box fx-jc-c'>
<div className="home-page fx-box fx-fd-c">
            <div className="fx-box home-container">
                <div className="fx-box fx-1 fx-fd-c home-main">
                    <div className='fx-box section-header fx-ai-c'>
                        <div>
                            <img alt="" src="/static/images/icon1.png" className='header-icon'/>
                        </div>
                        <div className='title'>主播表演</div>
                    </div>
                    <div className="fx-box fx-wrap live-list">
                       {rooms&&rooms.map((e,idx)=>{
                            return <Link key={'bk_'+idx} to={'/show/'+(e.roomId??idx)}><Box  title={e.title} poster={e.poster||DefaultPosterUrl}/></Link>
                       })}
                    </div>
                </div>
                <div className="right-col">
                    <div  className="right-col-header fx-box fx-ai-c fx-jc-c">
                        <div className='title'>
                        —— 公告 ——
                        </div>
                        </div>
                    <div className="right-col-content">
                        <p>
                            互联网管理办法
                        </p>
                        <p>
                            系统公告1
                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className='section-header'>
                <div className='fx-box section-header fx-ai-c'>
                        <div>
                            <img alt="" src="/static/images/icon2.png" className='header-icon'/>
                        </div>
                        <div className='title'>未开播</div>
                    </div>
                </div>
                <div className="fx-box fx-wrap live-list">
                    {offRooms&&offRooms.map((e,idx)=>{
                            return <Box  title={e.title} poster={e.poster||DefaultPosterUrl}/>
                       })}
                </div>
            </div>
        </div>
        </div>

    )
}
