import { useEffect, useState, useCallback } from "react";
import { Divider, Checkbox, Popup, Toast } from "react-vant";
import { WapNav } from "@react-vant/icons";
import "./Header.css"
import {Menu} from './menu';
import {Sign} from './sign';
import * as service from '../../service';
import {connect} from 'react-redux';

import TIM from '../../tim';

const HeaderRaw=(props)=>{
    const [showRegister,setShowRegister]  = useState(false);
    const [showLogin,setShowLogin]  = useState(false);
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [password2,setPassword2] = useState('');
    const [code,setCode] = useState('');
    const [display, setDisplay] = useState(false);

    const triggerMenu = useCallback(() => {
      if (display) {
        setDisplay(false);
      } else {
        setDisplay(true);
      }
    }, [display, setDisplay]);

    useEffect(()=>{
        if(props.showLoginDialog===true){
            setShowLogin(true);
        }
    },[setShowLogin,props.showLoginDialog]);

    return(
        <div className="fx-box debug header fx-ai-c">
            <div>
                <img src='/static/images/logo.png' className="logo"/>
            </div>

            <WapNav fontSize="2em" className="xs-shown" onClick={triggerMenu} />

            <div className={'nav' + (display ? '' : ' xs-hidden')}>
              <Menu></Menu>
              <Divider className="xs-divider" />
              {
                  props.loginInfo?
                  <div className="header-sign fx-box fx-ai-c">
                      <div className="btn" style={{paddingRight:12}} onClick={()=>{
                      }}>
                         用户 {props.loginInfo.username}
                      </div>
                      <div className="register btn" onClick={()=>{
                          props.dispatch({type:'LOGOUT'})
                      }}>
                          退出登录
                      </div>
                  </div>
                  :
                  <Sign
                  onLogin={()=>{
                      setShowLogin(true);
                  }}
                  onRegister={()=>{
                      setShowRegister(true);
                  }}></Sign>
              }
            </div>

            <Popup
            style={{
                width: 399,
                height: 436,
                background: 'white',
                borderRadius: 12
            }}
            visible={showRegister||showLogin}
            onClose = {()=>{
                setShowLogin(false);
                setShowRegister(false)
                props.dispatch({type:'CLOSE_LOGIN_DIALOG'})
            }}
            >
                <div style={{position:'relative',height:'95%'}} className='fx-box fx-fd-c'>
                    <div className="close-button fx-box fx-ai-c fx-jc-c btn" onClick={()=>{
                        setShowRegister(false);
                        setShowLogin(false);
                        props.dispatch({type:'CLOSE_LOGIN_DIALOG'})
                    }}>
                        <img src="/static/images/close.png"/>
                    </div>
                    <div className="fx-box h-s-top">
                        <div className={'btn '+(showLogin?"h-s-active":"h-s-inactive")} style={{marginRight:26}} onClick={()=>{
                            setShowLogin(true);
                            setShowRegister(false);
                        }}>已有账号</div>
                        <div className={'btn '+ (showRegister?"h-s-active":"h-s-inactive")} onClick={()=>{
                            setShowLogin(false);
                            setShowRegister(true);
                        }}>手机注册</div>
                    </div>
                    {
                        showRegister?<>
                            <div className="fx-box register-form-row">
                                <div className="fx-1 fx-box" style={{paddingRight:22}}>
                                    <input className="register-input fx-1" value={username} onChange={(e)=>{
                                        setUsername(e.target.value);
                                    }} placeholder='请输入手机号'/>
                                </div>
                                <div className="register-captcha color-white fx-box fx-ai-c fx-jc-c btn"
                                onClick={()=>{
                                    service.getVerifyCode({
                                        phone:username,
                                        type:'register'
                                    }).then(ret=>{
                                        console.log('get verify return:',ret);
                                    })
                                }}
                                >
                                    获取验证码
                                </div>
                            </div>
                            <div className="fx-box  register-form-row">
                                <input className="register-input fx-1" maxLength={6} value={code} onChange={(e)=>{
                                    setCode(e.target.value);
                                }} placeholder="输入验证码"/>
                            </div>
                            <div className="fx-box  register-form-row">
                                <input type='password' value={password} onChange={(e)=>{
                                    setPassword(e.target.value);
                                }} className="register-input fx-1" placeholder = '输入密码'/>
                            </div>
                            <div className="fx-box register-form-row">
                                <input type='password' value={password2}  onChange={(e)=>{
                                    setPassword2(e.target.value);
                                }} className="register-input fx-1" placeholder = '再次输入密码以验证'/>
                            </div>
                            <div className="fx-box register-form-row font-size13">
                            <Checkbox defaultChecked shape='square'>
                            我已阅读并同意
            </Checkbox>
                            <a href=''>《用户服务协议》</a><span>及</span><a href="#">《隐私条款》 </a>
                            </div>
                            <div className="fx-box fx-ai-c fx-jc-c" style={{marginTop:'auto'}}>
                                <div className=" register-submit fx-box fx-ai-c fx-jc-c color-white btn" onClick={()=>{
                                    service.register({
                                        username,
                                        password,
                                        code
                                    }).then(ret=>{
                                        setShowLogin(false);
                                        setShowRegister(false);
                                        console.log(ret);
                                    }).catch(err=>{
                                        console.error(err);
                                        err?.response?.data?.error&&Toast.info(err?.response?.data?.error);
                                    })
                                }}>
                                    注册
                                </div>
                            </div>
                        </>:null
                    }
                    {
                        showLogin?<>

                            <div className="fx-box  register-form-row">
                                <input className="register-input fx-1" value={username} onChange={(e)=>{
                                    setUsername(e.target.value);
                                }}/>
                            </div>
                            <div className="fx-box  register-form-row">
                                <input type='password' className="register-input fx-1" value={password} onChange={(e)=>{
                                    setPassword(e.target.value);
                                }}/>
                            </div>


                            <div className="fx-box fx-ai-c fx-jc-c" style={{marginTop:'auto'}}>
                                <div className=" register-submit fx-box fx-ai-c fx-jc-c color-white btn" onClick={()=>{
                                    service.login({username,password}).then(ret=>{
                                        console.log(ret);
                                        setShowLogin(false);
                                        setShowRegister(false);
                                        props.dispatch({
                                            type:'LOGIN_OK',
                                            payload:Object.assign({},ret.data,{username})
                                        })
                                    }).then(()=>{

                                    }).catch(err=>{
                                        console.error(err);
                                    });
                                }}>
                                    登录
                                </div>
                            </div>
                        </>:null
                    }

                </div>
            </Popup>

        </div>
    )
}

export const Header=connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        showLoginDialog:state.showLoginDialog
    }
},(dispatch)=>({dispatch}))(HeaderRaw);
