import "./footer.css";

export const Footer = (props)=>{
    return (
        <div className="debug footer">
            <div>
            COPYRIGHT © 2017 Enmoli Inc. ALL RIGHTS RESERVED
            <img alt="" src="/static/images/gov1.png" style={{width:25,height:31}}></img>    京公网安备11010802028681号 不良信息举报中心 网络110报警服务
            </div>
        </div>
    )
}
