/**
 * https://uglb.oss-accelerate.aliyuncs.com/ulive/xiaoya/xiaoya.m3u8
https://uglb.oss-accelerate.aliyuncs.com/ulive/ciwei/ciwei.m3u8
https://uglb.oss-accelerate.aliyuncs.com/ulive/huanhuan/huanhuan.m3u8
https://uglb.oss-accelerate.aliyuncs.com/ulive/qinglanlan/qinglanlan.m3u8
https://uglb.oss-accelerate.aliyuncs.com/ulive/shichenjin/shichenjin.m3u8
https://uglb.oss-accelerate.aliyuncs.com/ulive/zjyaoyao/zjyaoyao.m3u8
 */
export const STATICROOMS= [
    {title:'渔曼曼',id:1,poster:'/static/images/kol/渔曼曼.png',
      url:'https://uglb.oss-accelerate.aliyuncs.com/ulive/huanhuan/huanhuan.m3u8',
      status:0},
    {title:'暖晴 ',id:2,poster:'/static/images/kol/暖晴.png',url:'https://uglb.oss-accelerate.aliyuncs.com/ulive/qinglanlan/qinglanlan.m3u8',status:0},
    {title:'是辛程哦 ',id:3,poster:'/static/images/kol/是辛程哦.png',url:'https://uglb.oss-accelerate.aliyuncs.com/ulive/shichenjin/shichenjin.m3u8',status:0},
    {title:'迷鹿',id:4,poster:'/static/images/kol/迷鹿.png',url:'https://uglb.oss-accelerate.aliyuncs.com/ulive/xiaoya/xiaoya.m3u8',status:0},
    {title:'小西瓜',id:5,poster:'/static/images/kol/小西瓜.png',url:'https://uglb.oss-accelerate.aliyuncs.com/ulive/ciwei/ciwei.m3u8',status:0},
    {title:'猪猪哟',id:6,poster:'/static/images/kol/猪猪哟.png',url:'https://uglb.oss-accelerate.aliyuncs.com/ulive/zjyaoyao/zjyaoyao.m3u8',status:0},
    
]

export const offRooms= [
  {title:'栗子优biu',id:1,poster:'/static/images/kol/栗子优biu.jpg',
    url:'https://uglb.oss-accelerate.aliyuncs.com/ulive/huanhuan/huanhuan.m3u8',
    status:0},
  {title:'米奇喵',id:2,poster:'/static/images/kol/米奇喵.jpg',url:'https://uglb.oss-accelerate.aliyuncs.com/ulive/qinglanlan/qinglanlan.m3u8',status:0},
  {title:'小雨娘 ',id:3,poster:'/static/images/kol/小雨娘.jpg',url:'https://uglb.oss-accelerate.aliyuncs.com/ulive/shichenjin/shichenjin.m3u8',status:0},
  {title:'lcey雪椰',id:4,poster:'/static/images/kol/lcey雪椰.jpg',url:'https://uglb.oss-accelerate.aliyuncs.com/ulive/xiaoya/xiaoya.m3u8',status:0},
  
]


export const  Gifts =[{
    id:4,
    name:'么么哒',
    icon:'',
    price:'58'	
},
{
    id:1,
    name:'小香蕉',
    icon:'',
    price:'158'	
},
{
    id:2,
    name:'杜蕾斯',
    icon:'',
    price:'258'	
},
{
    id:5,
    name:'告白气球',
    icon:'',
    price:'520'	
},
{
    id:6,
    name:'比心',
    icon:'',
    price:'1024'	
},
{
    id:14,
    name:'海洋珍珠',
    icon:'',
    price:'2888'	
},
{
    id:7,
    name:'黄金跑车',
    icon:'',
    price:'5200'	
},
{
    id:8,
    name:'飞机',
    icon:'',
    price:'8888'	
},
    {
        id:3,
        name:'仙女棒',
        icon:'',
        price:'99'	
    },
    {
        id:9,
        name:'小黄瓜',
        icon:'',
        price:'199'	
    },
    {
        id:10,
        name:'口红',
        icon:'',
        price:'399'	
    },
    {
        id:13,
        name:'一箭穿心',
        icon:'',
        price:'666'	
    },
    {
        id:11,
        name:'粉玫瑰',
        icon:'',
        price:'999'	
    },
    {
        id:12,
        name:'抱抱熊',
        icon:'',
        price:'1314'	
    },
    {
        id:21,
        name:'包你满意',
        icon:'',
        price:'3999'	
    },
    {
        id:15,
        name:'刷我滴卡',
        icon:'',
        price:'13140'	
    },
    {
        id:19,
        name:'钻戒',
        icon:'',
        price:'9999'	
    },
    {
        id:16,
        name:'游轮',
        icon:'',
        price:'18888'	
    },
    {
        id:25,
        name:'飞艇',
        icon:'',
        price:'29999'	
    },
    {
        id:18,
        name:'梦幻城堡',
        icon:'',
        price:'66666'	
    },
    {
        id:17,
        name:'爱心火箭',
        icon:'',
        price:'88888'	
    },
    {
        id:20,
        name:'飞碟',
        icon:'',
        price:'131425'	
    }
];

export const DefaultPosterUrl='/static/images/live-default.png';

export const giftList=[
      {
        "giftId": "1",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/huojian.png",
        "lottieUrl": "https://assets5.lottiefiles.com/packages/lf20_t9v3tO.json",
        "price": 2989,
        "title": "火箭",
        "type": 1
      },
      {
        "giftId": "2",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/jidan.png",
        "lottieUrl": "",
        "price": 298,
        "title": "鸡蛋",
        "type": 0
      },
      {
        "giftId": "3",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/kiss.png",
        "lottieUrl": "",
        "price": 99,
        "title": "吻",
        "type": 0
      },
      {
        "giftId": "4",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/paoche.png",
        "lottieUrl": "",
        "price": 1998,
        "title": "跑车",
        "type": 0
      },
      {
        "giftId": "5",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/jianianhua.png",
        "lottieUrl": "",
        "price": 77,
        "title": "嘉年华",
        "type": 0
      },
      {
        "giftId": "6",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/meigui.png",
        "lottieUrl": "",
        "price": 99,
        "title": "玫瑰",
        "type": 0
      },
      {
        "giftId": "7",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/zhishengji.png",
        "lottieUrl": "",
        "price": 72,
        "title": "直升机",
        "type": 0
      },
      {
        "giftId": "8",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/dianzan.png",
        "lottieUrl": "",
        "price": 198,
        "title": "点赞",
        "type": 0
      },
      {
        "giftId": "9",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/bixin.png",
        "lottieUrl": "",
        "price": 34,
        "title": "比心",
        "type": 0
      },
      {
        "giftId": "10",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/bingqilin.png",
        "lottieUrl": "",
        "price": 33,
        "title": "冰淇淋",
        "type": 0
      },
      {
        "giftId": "11",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/xiaoxiong.png",
        "lottieUrl": "",
        "price": 76,
        "title": "玩偶",
        "type": 0
      },
      {
        "giftId": "12",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/dangao.png",
        "lottieUrl": "",
        "price": 110,
        "title": "蛋糕",
        "type": 0
      },
      {
        "giftId": "13",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/jinsepaoche.png",
        "lottieUrl": "",
        "price": 1888,
        "title": "豪华轿车",
        "type": 0
      },
      {
        "giftId": "14",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/youting.png",
        "lottieUrl": "https://assets4.lottiefiles.com/packages/lf20_DnLK6k.json",
        "price": 2989,
        "title": "游艇",
        "type": 0
      },
      {
        "giftId": "15",
        "giftImageUrl": "https://liteav.sdk.qcloud.com/app/res/picture/live/gift/chibang.png",
        "lottieUrl": "",
        "price": 267,
        "title": "翅膀",
        "type": 0
      }
    ]