import './App.css';
import Routers from './routers';
import TIM from './tim';
import {Provider} from 'react-redux';
import {store} from './redux';

import * as service from './service';
import { useEffect } from 'react';

function App() {
  useEffect(()=>{
    if(!store.getState().loginInfo){

      const ao=localStorage.getItem('oauth');
      console.log('%c checkLogin:','color:blue',ao);
      if(!ao)return;

      const authObject = JSON.parse(ao);
      service.checkLogin(authObject).then(ret=>{
        console.log(ret);
        store.dispatch({
          type:'LOGIN_OK',
          payload:Object.assign({},authObject,{username:ret.data.username})
        })
      }).catch(err=>{
        console.error(err);
      })
    }
  })

  console.log('%c TIM::STATUS:%o','color:blue',TIM.getStatus());

  return (
    <Provider store={store}>
      <div className="App">
        <Routers/>
      </div>
    </Provider>
  );
}

export default App;
