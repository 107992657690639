import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom";
import GiftPanel from "../../components/gift-panel/GiftPanel";
import { STATICROOMS } from "../../mock";
import tim from '../../tim';
import TIM from 'tim-js-sdk';

import './show.css';
import { connect } from "react-redux";

import * as service from '../../service';



const ShowRaw = (props)=>{
    const chatContent=useRef();
    const [nickname,setNickname] = useState('')
    const [messageText,setMessageText]  = useState('');
    // const [history,setHistory]=useState(DEFAULT_MESSAGES);

    const params = useParams();
    let playerInited = false;
    let groupJoined = false;
    //const pullUrl = `webrtc://pull.santiaoyukeji.cn/live/${params.id}`;
    const pullUrl = STATICROOMS[params.id].url;

    let onMessageReceived = function(event) {
        // event.data - 存储 Message 对象的数组 - [Message]
        const messageList = event.data;
        messageList.forEach((message) => {
            console.log('%c message received:%o','color:blue',message);

          if (message.type === TIM.TYPES.MSG_TEXT) {
            if(message.to==params.id){
                props.dispatch({type:'MESSAGE_RECEIVE',payload:{sender:message.from,content:message.payload.text,datetime:new Date().valueOf}})
            }
            // 文本消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.TextPayload
          } else if (message.type === TIM.TYPES.MSG_IMAGE) {
            // 图片消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.ImagePayload
          } else if (message.type === TIM.TYPES.MSG_SOUND) {
            // 音频消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.AudioPayload
          } else if (message.type === TIM.TYPES.MSG_VIDEO) {
            // 视频消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.VideoPayload
          } else if (message.type === TIM.TYPES.MSG_FILE) {
            // 文件消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.FilePayload
          } else if (message.type === TIM.TYPES.MSG_CUSTOM) {
            if(message.to==params.id){
                props.dispatch({type:'MESSAGE_RECEIVE',payload:{sender:message.from,content:message.payload.text,datetime:new Date().valueOf}})
            }
            // 自定义消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.CustomPayload
          } else if (message.type === TIM.TYPES.MSG_MERGER) {
            // 合并消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.MergerPayload
          } else if (message.type === TIM.TYPES.MSG_LOCATION) {
            // 地理位置消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.LocationPayload
          } else if (message.type === TIM.TYPES.MSG_GRP_TIP) {
            // 群提示消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.GroupTipPayload
          } else if (message.type === TIM.TYPES.MSG_GRP_SYS_NOTICE) {
            // 群系统通知 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.GroupSystemNoticePayload
          }
        });
      };



    const sendGift = async (gift)=>{

        const toUser = params.id;

        const result=await service.giveGift(toUser,gift.giftId,1,gift.price);

        const MJSON = {
            "businessID": "TUIGift",
            "data": {
                "extInfo": {
                    "avatarUrl": "https://liteav.sdk.qcloud.com/app/res/picture/voiceroom/avatar/user_avatar5.png",
                    "nickName": "厚土哥哥",
                    "userID": "1"
                },
                "giftId": ""+gift.giftId,
                "imageUrl": gift.giftImageUrl,
                "lottieUrl": gift.lottieUrl,
                "message": gift.title
            },
            "platform": "Web",
            "version": "1.0"
        }
        let message = tim.createCustomMessage({
            to: ''+params.id,
            conversationType: TIM.TYPES.CONV_GROUP,
            payload:{data:JSON.stringify(MJSON)
        }});
        console.log('%c message:%o','color:blue',message);
        //tim.joinGroup({groupID:params.id}).then(()=>{
        //    tim.sendMessage(message);
        //}).then(ret=>{
        tim.sendMessage(message).then(ret=>{
        // history.push({sender:props.loginInfo.userId, content: messageText, datetime:new Date().valueOf})
        // setHistory(history.slice());
            props.dispatch({type:'MESSAGE_SEND',payload:{sender:props.loginInfo.userId, content: '送出 ', datetime:new Date().valueOf,type:'gift',image:gift.giftImageUrl}})
        // setMessageText('');
        }).catch(err=>{
            console.log(err);
        });
    }
    useEffect(()=>{

        console.log('%c ================','color:blue')
        const groupID=''+params.id;

        tim.joinGroup({groupID}).then((ret)=>{

            props.dispatch({type:'JOIN_GROUP',payload:groupID});
            try{
                tim.off(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
            }catch(err){
                console.error(err);
            }

            tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
        }).catch(err=>{
            console.error('joinGroup error:',err);
        })

        return (()=>{

            tim.quitGroup(groupID).then(()=>{
                props.dispatch({type:'QUIT_GROUP'})
            })
            .catch(err=>{

            });

            try{
                tim.off(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
            }catch(err){
                console.error(err);
            }
        })
    },[])

    useEffect(()=>{
        console.log('%c TIM::STATUS:%o','color:blue',tim.getStatus());
        let videoElement= document.getElementById('player-container-id_html5_api');
        if(!videoElement){
            videoElement=document.getElementById('player-container-id');

            // const url ="http://pull.santiaoyukeji.cn/live/fu1234.m3u8";
            // const url = "rtmp://pull.santiaoyukeji.cn/live/fu1234";
            //const url = "webrtc://pull.santiaoyukeji.cn/live/fu1234";
            var player = window.TCPlayer('player-container-id', {autoplay:true,controlBar:{playToggle:false}}); // player-container-id 为播放器容器 ID，必须与 html 中一致
            player.src(pullUrl);
            playerInited=true;

            if(!nickname){
                const room =STATICROOMS[params.id];
                room&&setNickname(room.title);
            }
        }
    },[setNickname,props]);
    useEffect(()=>{
        chatContent.current.scrollTop=chatContent.current.scrollHeight;
    },[props.messages]);

    return (
      <div className="show-container">
        <div className="show-main">
            <div className="section-header fx-box fx-ai-c">
                    <div className="section-header-icon">
                        <img src="/static/images/icon1.png" className="header-icon"></img>
                    </div>
                    <div style={{marginLeft:22}}>{nickname} 的直播间</div>
                    {/* <div className="btn chat-send font-size13" onClick={()=>{
                        tim.joinGroup({groupID:params.id}).then(ret=>{
                            console.log(ret);
                        })
                    }}>join group</div> */}
            </div>
            <div className="fx-box show-panel">
                <div className="fx-box fx-fd-c fx-1 left-panel">
                    <div className="show-video fx-box ">
                        <video
                          id="player-container-id"
                          className="player"
                          preload="auto"
                          playsInline
                          webkit-playsinline="true"
                        ></video>
                    </div>
                    <GiftPanel onSend = {(gift)=>{
                        console.log('send gift');
                        sendGift(gift);
                    }}/>
                </div>
                <div className="chat fx-box fx-fd-c debug">
                    <div className="chat-content fx-1" ref={chatContent} id='chat-content'>
                        {
                            props.messages.map((message,idx)=>{

                                    if(message.type=='gift'){
                                        return(<p key={'k_'+idx}>{message.sender}:{message.content}<img src={message.image}/></p>);
                                    }
                                    else
                                        return(<p key={'k_'+idx}>{message.sender}:{message.content}</p>);

                            })
                        }
                    </div>
                    <div className="chat-input-form debug">
                        <input className="chat-input" value={messageText} onChange={(e)=>{
                            setMessageText(e.target.value)
                        }}>
                        </input>
                        <div>
                            <div className="chat-send btn" onClick = {()=>{
                                if(!props.loginInfo){
                                    props.dispatch({type:'SHOW_LOGIN_DIALOG'});
                                    return;
                                }
                                if(!messageText)return;
                                let message = tim.createTextMessage({
                                    to: ''+params.id,
                                    conversationType: TIM.TYPES.CONV_GROUP,
                                    // 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
                                    // 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
                                    // priority: TIM.TYPES.MSG_PRIORITY_NORMAL,
                                    payload: {
                                      text: messageText
                                    },
                                    // v2.20.0起支持C2C消息已读回执功能，如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
                                    needReadReceipt: true
                                    // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到，v2.10.2起支持）
                                    // cloudCustomData: 'your cloud custom data'
                                  });
                                console.log(message.type);
                                tim.sendMessage(message).then(ret=>{
                                    // history.push({sender:props.loginInfo.userId, content: messageText, datetime:new Date().valueOf})
                                    // setHistory(history.slice());
                                    props.dispatch({type:'MESSAGE_SEND',payload:{sender:props.loginInfo.userId, content: messageText, datetime:new Date().valueOf}})
                                    setMessageText('');
                                }).catch(err=>{
                                    console.log(err);
                                });
                            }}>
                                发送
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
      </div>
    )
}

export const Show = connect(state=>{
    return {loginInfo:state.loginInfo,messages:state.messages,currentGroup:state.currentGroup}
},dispatch=>({dispatch}))(ShowRaw);
