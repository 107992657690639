import { useEffect, useState } from "react";
import "./charge.css";

import * as service from '../../service';

import {connect} from 'react-redux';
import { useNavigate } from "react-router-dom";

const chargeTypes=[
    {
        price:100,
        amount:1000
    },
    {
        price:300,
        amount:3000
    },
    {
        price:400,
        amount:4000
    },
    {
        price:500,
        amount:5000
    },
]

const ChargeType = (props)=>{
    return (
        <div className={"charge-type btn fx-box fx-fd-c fx-ai-c fx-jc-c"+(props.active?' active':'')} onClick={()=>{
                props.onClick&&props.onClick()
        }}>
            <div>{props.price} 元</div>
            <div>{props.amount} 金币</div>
        </div>
    )
}

const ChargeRaw = (props)=>{

    const [activeType,setActiveType] = useState(0);
    const [account,setAccount]  = useState(null);

    const navigate = useNavigate();

    useEffect(()=>{
        try{
            props.loginInfo&&!account&&service.getAccount().then(data=>{
                console.log('%c my account is:%o','color:blue',data);
                setAccount(data);
            })
        }
        catch(err){

        }

    })


    const createOrder = (param)=>{
        return service.createOrder(param).then(ret=>{
            console.log('%c place order return:%o','color:blue',ret.data);
            return ret.data;
        })
    }

    const payOrder = (id)=>{
        return service.payOrder(id).then(ret=>{
            console.log('%c pay order return:%o','color:blue',ret.data);
            window.open(ret.data.ticket,'_new');
            //return ret.data;
            navigate('/chargeok/'+id);
        })
    }

    return (
        <div className="charge-page fx-box fx-fd-c section">
            <div className="section-header fx-box fx-ai-c">
                <img alt="" src="/static/images/icon1.png" className="header-icon"></img>
                <div>
                    充值
                </div>
            </div>

            <div className="fx-box fx-fd-c charge-container">
                <div className="fx-box font-size26">
                    您当前帐户可用余额{account?.available_balance??'***'}金币
                </div>
                <div className="fx-box font-size17 charge-row">
                    充值账号：{props.loginInfo?.username??'***'}
                </div>
                <div className="fx-box font-size17 charge-row">
                    充值方式：<span>支付宝</span>
                </div>
                <div className="fx-box charge-row">
                    <div>充值金额</div>
                    <div className="fx-box charge-list">
                        {
                            chargeTypes.map((e,idx)=>{
                                return (
                                    <ChargeType key={'ctk_'+idx} price={e.price} amount={e.amount} active={idx===activeType} onClick={()=>{
                                        setActiveType(idx)
                                    }}/>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="fx-box charge-row ">
                    <div className="btn charge-submit" onClick={()=>{
                        createOrder({
                            volume:chargeTypes[activeType].price,
                            payment_method:'alipay',
                            memo:'.....1'
                        }).then(ret=>{
                            console.log(ret);
                            return payOrder(ret.id);
                        }).then(ret=>{
                            console.log(ret);
                        }).catch(err=>{
                            console.error(err);
                        })
                    }} >去支付</div>
                </div>
                <div className="charge-notice fx-box fx-fd-c">
                    <p>
                    温馨提示：
                    </p>
                    <p>
                    1.当充值过程中出现网页错误或者打开缓慢的情况，请首先查询银行交易记录检查扣款是否成功；然后查询自己的个人账户是否已经收到等值金币。切忌不要刷新
页面，防止充值失败。</p><p>
2.如果充值过程中出现因网络或系统繁忙导致扣款成功而金币没有增加的情况，请与我们的客服人员联系。</p><p>
3.支付完毕之后请一定等待“自动跳转返回”页面，以避免到账延迟。</p><p>
4.因近期网络诈骗频发，请您勿将充值二维码交给他人，或让他人进行充值。如您将充值二维码泄露给他人，可能会涉嫌刑事违法犯罪，届时我们将会协助公安机</p><p>
关提供您的相关信息配合调查；同时我们可能对您的账户进行处理，包括但不限于封禁账号，扣除涉案等额金币等方式。
                    </p>
                </div>
            </div>
        </div>
    )
}

export const Charge=connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        showLoginDialog:state.showLoginDialog
    }
},(dispatch)=>({dispatch}))(ChargeRaw);
