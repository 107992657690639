import { useEffect, useState } from "react";
import "./charge.css";

import * as service from '../../service';

import {connect} from 'react-redux';
import { useNavigate } from "react-router-dom";


const ChargeOKRaw = (props)=>{
    
    const [activeType,setActiveType] = useState(0);
    const [account,setAccount]  = useState(null);
    const navigate = useNavigate();
    useEffect(()=>{
        try{
            props.loginInfo&&!account&&service.getAccount().then(data=>{
                console.log('%c my account is:%o','color:blue',data);
                setAccount(data);
            })
        }
        catch(err){

        }
        
    })


    const createOrder = (param)=>{
        return service.createOrder(param).then(ret=>{
            console.log('%c place order return:%o','color:blue',ret.data);
            return ret.data;
        })
    }

    const payOrder = (id)=>{
        return service.payOrder(id).then(ret=>{
            console.log('%c pay order return:%o','color:blue',ret.data);
            window.open(ret.data.ticket,'_new');
            //return ret.data;
        })
    }

    return (
        <div className="charge-page fx-box fx-fd-c section">
            <div className="section-header fx-box fx-ai-c">
                <img src="/static/images/icon1.png" className="header-icon"></img>
                <div>
                    充值
                </div>
            </div>

            <div className="fx-box fx-fd-c charge-container">
                <div style={{marginBottom:50}}>
                    等待支付结果...
                </div>
                <div className="btn charge-submit" onClick={()=>{
                    navigate('/');
                }}>
                    支付完成
                </div>
                <div className="charge-notice fx-box fx-fd-c">
                    <p>
                    温馨提示：
                    </p>
                    <p>
                    1.当充值过程中出现网页错误或者打开缓慢的情况，请首先查询银行交易记录检查扣款是否成功；然后查询自己的个人账户是否已经收到等值金币。切忌不要刷新
页面，防止充值失败。</p><p>
2.如果充值过程中出现因网络或系统繁忙导致扣款成功而金币没有增加的情况，请与我们的客服人员联系。</p><p>
3.支付完毕之后请一定等待“自动跳转返回”页面，以避免到账延迟。</p><p>
4.因近期网络诈骗频发，请您勿将充值二维码交给他人，或让他人进行充值。如您将充值二维码泄露给他人，可能会涉嫌刑事违法犯罪，届时我们将会协助公安机</p><p>
关提供您的相关信息配合调查；同时我们可能对您的账户进行处理，包括但不限于封禁账号，扣除涉案等额金币等方式。
                    </p>
                </div>
            </div>
        </div>
    )
}

export const ChargeOK=connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        showLoginDialog:state.showLoginDialog
    }
},(dispatch)=>({dispatch}))(ChargeOKRaw);