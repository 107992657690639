import "./charge.css"
export const Pay = (props)=>{
    return (
        <div className="pay-page fx-box fx-fd-c section">
            <div className="section-header fx-box fx-ai-c">
                <img src="/static/images/icon1.png" className="header-icon"></img>
                <div>
                    充值
                </div>
            </div>

            <div className="fx-box fx-fd-c">
                <div className="fx-box">
                    订单号： 489208309213
                </div>
                
            </div>
        </div>
    )
}