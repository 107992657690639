import Axios from 'axios';
//const BASE_HOST='http://101.42.166.156';
//const BASE_HOST='http://localhost:3000';
// const BASE_HOST='http://fuboo.io';
const BASE_HOST='https://uulechang.net';

let token = null;

export const setToken = (t)=>{
    token = t;
}
export const getToken = ()=>{
    return token;
}

const fubooPost=(url,param)=>{
    return Axios.post(url,param,{
        headers:{
            'Authorization':  'Bearer '+getToken().access_token
        }
    })    
}

const fubooGet=(url)=>{
    return Axios.get(url,{
        headers:{
            'Authorization':  'Bearer '+getToken().access_token
        }
    })
}

export const checkLogin = (token)=>{
    setToken(token)
    const url = BASE_HOST+'/api/v1/check';
    return fubooPost(url,{})
}

export const login=(options)=>{
    //todo
    const url =BASE_HOST+'/api/v1/token';
    options.grant_type='password';

    return Axios.post(url,options,{
        headers:{
            'Authorization':  'Basic ZnVib286YXBw'
        }
    }).then(ret=>{
        localStorage.setItem('oauth',JSON.stringify(ret.data));
        setToken(ret.data);
        return ret;
    })
}



export const usersig=(options)=>{
    //todo
    const url =BASE_HOST+'/api/v1/usersig';

    return Axios.get(url,{
        headers:{
            'Authorization':  'Bearer '+getToken().access_token
        }
    }).then(data=>{
        console.log(data);
        return data;
    })
}

export const register = (options)=>{
    const url = BASE_HOST+'/api/v1/signup';
    return Axios.post(url,options,{
        headers:{
            'Authorization':  'Bearer f78492f0ddf1b1739cd0d4e6e7e692cf2c47f82a'
        }
    })

}

export const getVerifyCode = (options)=>{
    const url = BASE_HOST+'/api/v1/send/code';
    return Axios.post(url,options,{
        headers:{
            'Authorization':  'Bearer f78492f0ddf1b1739cd0d4e6e7e692cf2c47f82a'
        }
    })
}

export const getRooms = (options)=>{
    const url = BASE_HOST+'/base/v1/rooms/query_room';
    
    return  Axios.get(url);
}

export const giveGift =(toUser,giftId,giftCount,giftPrice)=>{
    const param = {
        gift_id:giftId,
        to_user_id:toUser,
        giftCount:giftCount||1,
        giftPrice
    }
    const url = BASE_HOST+'/api/v1/give';
    console.log('==>giveGift:',param);
    return fubooPost(url,param).then(ret=>{
        console.log(ret);
        return ret.data;
    }).catch(err=>{
        console.error(err);
        throw err;
    })
}

export const getAccount = ()=>{
    const url =BASE_HOST+ '/api/v1/account'
    return fubooGet(url).then(ret=>{
        return ret.data;
    })
}

export const createOrder = (param)=>{
    const url = BASE_HOST+'/api/v1/order';
    return fubooPost(url,param);
}

export const payOrder = (id)=>{
    const url = BASE_HOST+'/api/v1/pay/'+id;
    return fubooPost(url,{});
}