import { useRef, useState } from 'react';
import { Gifts,giftList } from '../../mock';
import './gift-panel.css';


export default (props)=>{
    const content = useRef();
    const container = useRef();

    const moveLeft=()=>{
        let rect = content.current.getBoundingClientRect();
        let item = content.current.querySelector('.active');

        if (item) {
          let { width } = item.getBoundingClientRect();
          let newPosition = (parseInt(content.current.style.left) || 0) - width;

          if (newPosition > rect.width * -1) {
            content.current.style.left = newPosition+'px';
          }
        }
    }

    const moveRight=()=>{
        let rect = content.current.getBoundingClientRect();
        let item = content.current.querySelector('.active');

        if (item) {
          let { width } = item.getBoundingClientRect();
          let newPosition = (parseInt(content.current.style.left) || 0) + width;

          if (newPosition <= 0) {
            content.current.style.left = newPosition+'px';
          }
        }
    }

    const [currentGift,setCurrentGift] = useState(giftList[0]);
    const [currentGiftIdx,setCurrentGiftIdx] = useState(0);

    return (
        <div className="gift-panel fx-box fx-fd-r">
            <div className='gift-control btn' onClick={moveLeft}>
                {'<'}
            </div>
            <div className='fx-1 gift-container fx-box fx-ai-c' ref={container}>
                <div className='fx-box gift-content' ref={content} id='gift-content'>
                    {giftList.map((e,idx)=>{
                        return (
                            <div key={'gk_'+idx} className={'gift '+(idx==currentGiftIdx?'active':'')} onClick={()=>{
                                setCurrentGiftIdx(idx);
                                setCurrentGift(e);

                            }}>
                                <img alt="" src={e.giftImageUrl}/>
                                <div>{e.price}</div>
                                <div>{e.title}</div>
                            </div>
                        )
                    })}
                </div>

            </div>
            <div className='gift-control btn' onClick={()=>{
                moveRight();
            }}>
            {'>'}
            </div>
            <div className='gift-desc'>
                <div>
                    送出 <span className='active-gift'>{currentGift.title}</span> * 1
                </div>
                <div className='btn gift-send' onClick={()=>{
                    props.onSend&&props.onSend(currentGift);
                }}>
                    发送按钮
                </div>
            </div>
        </div>
    )
}
