export const Sign = (props)=>{
    return(
        <div className="header-sign">
            <div className="login btn" onClick={()=>{
                props.onLogin&&props.onLogin();
            }}>
                登录
            </div>
            <div className="register btn" onClick={()=>{
                props.onRegister&&props.onRegister();
            }}>
                注册
            </div>
        </div>
    )
}